import React from "react"

class Benificial extends React.Component {

  render() {
    return (

      <section className="benifor ido pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center"><span className="bluecolor">Salient Features </span> Of Our IDO Development
            </h2>
            <p className="text-center">
            Get into the innovative journey as we redefine the dynamic features of IDO solutions to the possibilities driving your project toward success.
            </p>
            <div className="d-lg-flex flex-wrap add-one">
              <div className="square" >
                <h3>Compatibility</h3>
                <p className="pharagraph">
                Our Initial DEX Offering process is compatible with numerous popular networks and DEX platforms enabling cross-chain functionality for seamless transactions across multiple platforms.
                </p>
              </div>
              <div className="square" >
                <h3>KYC/AML Verification</h3>
                <p className="pharagraph">
                The IDO platform is compiled with KYC/AML regulatory requirements. Thus, preventing fraudulent users and scammers from entering the platform.
                </p>
              </div>
              <div className="square" >
                <h3>Effortless operation</h3>
                <p className="pharagraph">
                The high-value and popular tokens in the DEX platform facilitate the accumulation of higher amounts of liquidity and streamline an easy fundraising process.
                </p>
              </div>
              <div className="square" >
                <h3>Secure listing</h3>
                <p className="pharagraph">
                The DEX platform is built incorporating robust security mechanisms and has undergone numerous regulatory audits. This enhances the security of the listed token and DEX platform.
                </p>
              </div>
              <div className="square" >
                <h3>Token Distribution</h3>
                <p className="pharagraph">
                For faultless and efficient fundraising methodologies the creation and distribution of tokens plays a pivotal role. This streamlines and facilitates the process of launching the tokens on DEXs.
                </p>
              </div>
              <div className="square" >
                <h3>Open Fundraising</h3>
                <p className="pharagraph">
                The multi-staking module enables the participation of all kinds of users and investors to engage seamlessly in crowdfunding and campaigns without any hassles in the DEX platform. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Benificial
